@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none; }

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: #FAFAFF;
  min-width: 320px; }

input {
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 350px;
  min-width: 300px;
  width: 100%;
  font-size: 20px;
  padding: 0 14px;
  margin: 20px 0; }

$buttonColor: #4F74F9;
$textColor: #333333;

input[type="submit"] {
  border: 2px solid $buttonColor;
  background-color: #0000;
  color: $buttonColor; }

input[type="submit"]:hover {
  cursor: pointer; }

form {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center; }

h1 {
  font-size: 32px;
  margin: 26px 0;
  color: $textColor;
  text-align: center; }

a {
  color: $textColor;
  font-size: 18px; }
