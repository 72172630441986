@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
.home{display:flex;flex-direction:column;align-items:center;justify-content:center}.home .form-wrapper{max-width:350px;width:90vw;min-width:300px}.home .form-wrapper a{display:inline-block;margin:24px 0}

.password-recovery{display:flex;flex-direction:column;align-items:center;justify-content:center}.password-recovery p{max-width:350px;min-width:300px;width:90vw;line-height:28px;font-size:18px;color:#737380;margin:14px 0}.password-recovery .form-wrapper{max-width:350px;width:90vw;min-width:300px}.password-recovery .form-wrapper a{display:inline-block;margin:24px 0}.password-recovery .form-wrapper a span{display:inline-block;margin-left:10px;text-decoration:underline}

.recovery-code{display:flex;flex-direction:column;align-items:center;justify-content:center}.recovery-code p{max-width:350px;min-width:300px;width:90vw;line-height:28px;font-size:18px;color:#737380;margin:14px 0}.recovery-code .form-wrapper{max-width:350px;width:90vw;min-width:300px}.recovery-code .form-wrapper a{display:inline-block;margin:24px 0}.recovery-code .form-wrapper a span{display:inline-block;margin-left:10px;text-decoration:underline}

.password-change{display:flex;flex-direction:column;align-items:center;justify-content:center}.password-change .form-wrapper{max-width:350px;width:90vw;min-width:300px}.password-change .form-wrapper a{display:inline-block;margin:24px 0}.password-change .form-wrapper a span{display:inline-block;margin-left:10px;text-decoration:underline}

*{font-family:Roboto, sans-serif;box-sizing:border-box;padding:0;margin:0;outline:none}#root{min-height:100vh;display:flex;flex-direction:column;align-items:stretch;justify-content:center;background-color:#FAFAFF;min-width:320px}input{height:60px;border:1px solid #ddd;border-radius:8px;max-width:350px;min-width:300px;width:100%;font-size:20px;padding:0 14px;margin:20px 0}input[type="submit"]{border:2px solid #4F74F9;background-color:#0000;color:#4F74F9}input[type="submit"]:hover{cursor:pointer}form{width:100%;max-width:350px;display:flex;flex-direction:column;align-items:stretch;justify-content:center}h1{font-size:32px;margin:26px 0;color:#333;text-align:center}a{color:#333;font-size:18px}

